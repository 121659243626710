import './components.css'
import { useEffect, useState } from 'react'
export function ItemVeiculo(props) {
  const dados = props.dados
  return (
    <div onMouseDown={props.onClick} className={props.onClick ? 'ItemVeiculoContainer' : 'ItemVeiculoContainerNotHover'}>
      <div className='ItemVeiculoSubContainer1'>
        <img alt={dados.marca} src={`https://minhakondor.danyllo106.com/marcas/${dados.marca.toLowerCase()}.jpg`} />
      </div>
      <div className='ItemVeiculoSubContainer2'>
        <p className='ItemVeiculoModelo'>{dados.modelo}</p>
        <p className='ItemVeiculoDescricao'>{`${dados.marca} - De ${dados.ano_de} até ${dados.ano_ate}`} </p>
      </div>

    </div>
  )
}
export function ItemProduto(props) {
  const dados = props.dados
  return (
    <div className='ItemProdutoContainer'>
      <img className='ItemProdutoImg'
        alt={dados.referencia + " - " + dados.ah + "AH - Baterias Kondor - Minha Kondor"}
        src={`https://minhakondor.danyllo106.com/baterias/${dados.referencia}.png`} />
      <p className='ItemProdutoAH'>{dados.ah}AH</p>
      <table className='ItemProdutoTable'>
        <tbody>
          <tr>
            <th>Referência</th>
            <td>{dados.referencia}</td>
          </tr>
          <tr>
            <th>CCA</th>
            <td>{dados.cca}</td>
          </tr>
          <tr>
            <th>Peso</th>
            <td>{dados.peso}kg</td>
          </tr>
          <tr >
            <th style={{ width: 10 }}>Comprimento Largura Altura</th>
            <td>{dados.comprimento}cm {dados.largura}cm {dados.altura}cm</td>
          </tr>
          <tr>
            <th colSpan={2} style={{ textAlign: "center" }}>
              <p style={{ textAlign: 'start' }}>Polaridade</p>
              <img
                style={{ height: 100 }}
                alt={dados.polaridade + " - Baterias Kondor - Minha Kondor"}
                src={require(`../assets/polos/${dados.polaridade}.svg`).default} />
            </th>
          </tr>
        </tbody>
      </table>
      <button
        onClick={props.getCompativeis || props.onClick}
        className='ItemProdutoButton'>Veículos Compativeis</button>
    </div>
  )
}
export function Kondor360(props) {
  const [number, setNumber] = useState(1)
  const [delay, setDelay] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setDelay(false)
    }, 2000)
  }, [])

  return (
    <>
      <div className='ContainerKondor360'>
        <div className='card' >
          <div style={{ zIndex: 1 }}>

            {
              delay ?
                <img alt='SF22MPD - Baterias Kondor - Minha Kondor' style={{ opacity: 1 }} src={require(`../assets/kondor/${number}.png`).default} />
                :
                [...Array(53)].map((el, i) => (
                  <div key={i}>
                    <span style={{ '--i': i }}></span>
                    <img alt='SF22MPD - Baterias Kondor - Minha Kondor' src={require(`../assets/kondor/${i + 1}.png`).default} />
                  </div>
                ))
            }
          </div>
          <div className='cardMobile'>
            <img alt='SF22MPD - Baterias Kondor - Minha Kondor' style={{ opacity: 1 }} src={require(`../assets/kondor/${number}.png`).default} />
            <input type="range" min={1} max={53}
              onChange={(e) => {
                setNumber(e.target.value)
              }} ></input>
          </div>
        </div>
        <div className='orbit'></div>
      </div>


    </>
  )
}