import { useState } from "react"
import api from "../utils/api"
import { ItemProduto, ItemVeiculo } from "../utils/components"
import './style.css'
import { BsChevronLeft, BsSearch } from 'react-icons/bs';
import { debounce } from "throttle-debounce";
import { toast, ToastContainer } from "react-toastify";
export function BateriasResult(props) {
  const [compativeis, setCompativeis] = useState([])
  const [defaultCompativeis, setDefaultCompativeis] = useState([])

  const [bateria, setBateria] = useState(props.item ? props.item : null)
  const [showCompativeis, setShowCompativeis] = useState(props.item ? true : false)
  const [loading, setLoading] = useState(false)
  const selecionado = props.selecionado

  useState(() => {
    if (props.item)
      veiculosCompativeis(props.item)
  }, [props.item])

  async function veiculosCompativeis(item) {
    setBateria(item)
    setLoading(true)
    await api.get(`/veiculosCompativeis?item=${item.item}`)
      .then(async result => {
        setCompativeis([...compativeis, ...result.data])
        setDefaultCompativeis([...compativeis, ...result.data])
        setShowCompativeis(true)
        setLoading(false)
      })
      .catch(err => {
        toast.error("Erro ao Consultar Banco de Dados")
        setLoading(false)
      })
  }

  async function buscaCompativeis(text) {
    if (text.trim().length >= 2) {
      setLoading(true)
      await api.get(`/buscaCompativeis?item=${bateria.item}&busca=${text.toUpperCase()}`)
        .then(async result => {
          setCompativeis(result.data)
          setLoading(false)
        })
        .catch(err => {
          toast.error("Erro ao Consultar Banco de Dados")
          setLoading(false)
        })
    } else if (text.trim() === '') {
      setCompativeis(defaultCompativeis)
    }
  }
  return (
    <div style={{ zIndex: 50 }} className={selecionado || bateria ? "BateriasResultContainer" : ''}>
      <ToastContainer />
      {
        selecionado && !showCompativeis ?
          <div className='BodyContainer' >
            <p className='ContainerVoltar' onClick={props.onClose}><BsChevronLeft /> Voltar</p>
            <div className='InfoSelecionado'>
              <div className='InfoSelecionadoSubContainer1'>
                <img alt={selecionado.marca} src={`https://minhakondor.danyllo106.com/marcas/${selecionado.marca.toLowerCase()}.jpg`} />
              </div>
              <div className='InfoSelecionadoSubContainer2'>
                <p className='InfoSelecionadoModelo'>{selecionado.modelo}</p>
                <p className='InfoSelecionadoDescricao'>{`${selecionado.marca} - De ${selecionado.ano_de} até ${selecionado.ano_ate}`} </p>
              </div>
            </div>
            <div className="ProdutosContainer">
              {
                selecionado.baterias.map((el, index) => (
                  <ItemProduto
                    key={index}
                    dados={el}
                    getCompativeis={() => veiculosCompativeis(el)}
                  />
                ))
              }
            </div>
          </div>
          : showCompativeis ?
            <div className='BodyContainerCompativeis'>
              <p className='ContainerVoltar' onClick={() => props.item ? props.onClose() : setShowCompativeis(false)}><BsChevronLeft /> Voltar</p>
              <div className='InfoBateriaSelecionada'>
                <div className='InfoBateriaSelecionadaSubContainer1'>
                  <img
                    alt={bateria.referencia + " - " + bateria.ah + "AH Baterias Kondor - Minha Kondor"}
                    className='InfoBateriaSelecionadaImg'
                    src={`https://minhakondor.danyllo106.com/baterias/${bateria.referencia}.png`} />
                </div>
                <div className='InfoBateriaSelecionadaSubContainer2'>
                  <p className='InfoBateriaSelecionadaAH'>Bateria {bateria.ah}AH</p>
                  <p className='InfoBateriaSelecionadaReferencia'>Referência: {bateria.referencia}</p>
                </div>

              </div>

              <div className="CompativeisContainer">
                <div className='ContainerPesquisa'>
                  <p className='span'>Pesquisar veiculo compativel</p>
                  <div>
                    <input
                      onChange={debounce(1000, (text) => { buscaCompativeis(text.target.value) })}
                      className='inputPlaca'
                      placeholder={props.selecionado ? 'Ex: ' + selecionado.modelo : 'Ex: Gol 2018'} />
                    <BsSearch size={32} fill={'#ccc'} style={{ padding: 20 }} />
                  </div>
                </div>
                <p className='InfoBateriaSelecionadaAH' style={{ padding: 10, fontStyle: 'italic', color: '#ccc' }}>Veículos Compativeis</p>
                {
                  compativeis.length > 0 ?
                    <>
                      {loading && (
                        <div className='ContainerNenhum'>
                          <p >Carregando...</p>
                        </div>
                      )}

                      {
                        compativeis.map((el, index) => (
                          <ItemVeiculo
                            key={index}
                            dados={el}
                          />
                        ))
                      }

                    </>
                    : compativeis.length === 0 && loading ?
                      <div className='ContainerNenhum'>
                        <p >Carregando...</p>
                      </div>
                      : <div className='ContainerNenhum'>
                        <p >Nenhum veículo encontrado :(</p>
                      </div>
                }
              </div>
            </div>
            :
            null
      }
    </div>
  )

}