import React, { useEffect, useRef, useState } from 'react';
import api from './utils/api';
import './style.css'

import LazyLoad from 'react-lazyload';
import { BsSearch, BsChevronLeft, BsChevronRight, BsArrowRepeat } from 'react-icons/bs';
import { debounce } from 'throttle-debounce';
import sinespApi from 'sinesp-api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ItemVeiculo, Kondor360, ItemProduto } from './utils/components';
import { BateriasResult } from './pages/BateriasResult';
import Kondor from './assets/kondor-logo.svg';



function App() {
  const [veiculos, setVeiculos] = useState([])
  const [baterias, setBaterias] = useState([])
  const [listBaterias, setListBaterias] = useState([])
  const [selecionado, setSelecionado] = useState(null)
  const [item, setItem] = useState(null)
  const [focus, setFocus] = useState('')
  const [tempo, setTempo] = useState(0)
  const [textManual, setTextManual] = useState('')
  const [textPesquisa, setTextPesquisa] = useState('')
  const [loading, setLoading] = useState(false)
  const [quantBateria, setQuantBateria] = useState(10)

  const [text, setText] = useState('');
  const foo = useRef();
  const myRef = useRef(null)
  const Header2 = useRef(null)
  const Header3 = useRef(null)
  const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
  }
  const [inputRef, setInputFocus] = useFocus()

  function tick() {
    setTempo(prevTempo => prevTempo - 1)
  }

  useEffect(() => {
    listaBaterias()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tempo === 0) {
      clearInterval(foo.current);
    }
  }, [tempo])

  async function pesquisarPlaca() {
    const texto = text.replace(/[^a-z0-9]/gi, '').toUpperCase()

    if (texto.trim().length >= 6)
      await sinespApi.search(texto)
        .then(async (result) => {

          setTempo(70)
          foo.current = setInterval(() => tick(), 1000)
          tick()
          const t = `${result.modelo} ${result.anoModelo}  `;
          setFocus('MANUAL')
          setTextManual(t)
          pesquisar(t)
        })
        .catch(err => {
          const msg = err.message === '' ? 'Erro: Placa não encontrada na base de dados, utilize a pesquisa manual' : 'Erro: ' + err.message;
          toast.error(msg)
          setTempo(70)
          foo.current = setInterval(() => tick(), 1000)
          tick()
        })
  }

  async function pesquisar(text) {
    setLoading(true)
    if (text.trim().length >= 2)
      await api.get('/buscarBateria?busca=' + text.toUpperCase())
        .then(async result => {
          setVeiculos(result.data)
          setLoading(false)
        })
        .catch(err => {
          toast.error("Erro ao Buscar Bateria")
          console.log(err)
          setLoading(false)
        })
    else
      setVeiculos([])
    setLoading(false)
  }

  async function listaBaterias() {
    if (listBaterias.length === 0) {
      await api.get('/listarBateria')
        .then(async result => {
          const array = result.data.slice(0, 10)
          setBaterias(array)
          setListBaterias(result.data)
        })
        .catch(err => {
          toast.error("Erro ao Listar Baterias")
        })
    } else if (baterias.length < listBaterias.length) {
      const array = listBaterias.slice(quantBateria, quantBateria + 5 > listBaterias.length ? quantBateria + (listBaterias.length - baterias.length) : quantBateria + 5)
      setBaterias([...baterias, ...array])
      setQuantBateria(quantBateria + 5)
    }
  }

  async function filtrarBaterias(text) {

    const result = listBaterias.filter(el => { return el.ah.toString() === text.replace(/[^0-9]/g, '') || el.referencia.toUpperCase().search(text) >= 0 })
    setBaterias(text.trim() !== '' ? result : listBaterias.slice(0, 10))
    setQuantBateria(10)
  }

  return (
    <div className="App" style={{ display: selecionado || item ? 'block' : 'flex' }}>
      <ToastContainer />
      {
        selecionado || item ?
          <div>
            <BateriasResult
              onClose={() => {
                setSelecionado(null)
                setTextManual('')
                setItem(null)
              }}
              selecionado={selecionado}
              item={item}
            />
          </div>
          :
          <>
            <div style={{ display: focus !== 'MANUAL' ? 'contents' : "none" }}>
              <div className='Header0'>
                <img alt='Logo Minha Kondor' src={require('./assets/minhakondor logo.png').default} />
                <div>
                  <p onClick={() => Header2.current.scrollIntoView()}>Sua Bateria</p>
                  <p onClick={() => Header3.current.scrollIntoView()}>Nossos Produtos</p>
                </div>
              </div>

              <div className='Header1'>
                <div>
                  <Kondor360 />
                </div>
                <div className='subContainerBordao'>
                  <img src={Kondor} className='kondor-back' alt={'Logo Baterias Kondor - Minha Kondor'} />
                  <p className='bordao'><b>Qualidade</b> para seu carro,</p>
                  <p className='bordao'>tranquilidade <b>para você.</b></p>
                </div>
              </div>

              <div className='Header2' ref={Header2}>
                <div id='listras'>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div className='Listrado'></div>

                <p id="title">Saiba a bateria do seu veículo</p>
                <div id="flex">
                  <div>
                    <p className='span' >Digite o modelo do Veículo</p>
                    <input
                      onMouseDown={debounce(100, (e) => {
                        window.scrollTo(0, 0);
                        setInputFocus(e)
                      })}
                      onFocus={() => {
                        window.scrollTo(0, 0)
                        setFocus('MANUAL');
                      }}
                      className='inputBusca'
                      placeholder='Ex: Fiat Siena 2018'
                      onChange={debounce(1000, (text) => {
                        pesquisar(text.target.value.trim())
                      })} />
                  </div>

                  <p id='ou'>OU</p>
                  <div>
                    <p className='span'>Digite a placa do Veículo </p>
                    <div className='ContainerPlaca'>
                      <input
                        className='inputBusca'
                        placeholder='Ex: AAA1111'
                        onChange={(text) => setText(text.target.value.trim())} />
                      <button disabled={tempo === 0 ? false : true}
                        onMouseDown={() => pesquisarPlaca()}
                        className='buttonPlaca'><BsSearch /> Pesquisar</button>

                    </div>
                    {
                      tempo !== 0 ?
                        <p id='aguarde'>Aguarde <b>{tempo} segundos</b> para pesquisar a placa novamente</p>
                        : null
                    }
                  </div>
                </div>
              </div>

              <div ref={Header3} style={{ width: '100%', minHeight: 800 }}>
                <LazyLoad className='Header3' >
                  <div id='row'>
                    <div id='row2'>
                      <p className='span'>Pesquisar referência </p>
                      <input

                        className='inputBusca'
                        placeholder='Ex: 150ah'
                        onChange={(text) => {
                          setTextPesquisa(text.target.value)
                          filtrarBaterias(text.target.value.toUpperCase())
                        }} />
                    </div>
                    <p id="title">Conheça nossos produtos</p>
                  </div>

                  {
                    baterias.length === 0 && textPesquisa.trim() === '' ?
                      <div className="errorBaterias">
                        <p className="errorBateriasText">Não foi possivel carregar as baterias</p>
                        <button
                          onClick={() => listaBaterias()}
                          className="errorBateriasButton"><BsArrowRepeat /> Tentar novamente</button>
                      </div>
                      : baterias.length === 0 && textPesquisa.trim() !== '' ?
                        <div className="errorBaterias">
                          <p className="errorBateriasText">Nenhuma bateria encontrada</p>
                        </div>
                        :
                        <div id='row3'>
                          <div
                            className='chevron'
                            onClick={() => myRef.current.scrollTo(myRef.current.scrollLeft - 206.6, 0)}
                          >
                            <BsChevronLeft />
                          </div>
                          <div id='produtos' ref={myRef}>
                            {
                              baterias.map((el, index) => (
                                <ItemProduto
                                  key={index}
                                  dados={el}
                                  onClick={() => {
                                    window.scroll(0, 0)
                                    setItem(el)
                                  }}
                                />
                              ))
                            }
                            {
                              baterias.length < listBaterias.length && textPesquisa.trim() === '' && baterias.length > 9 && (
                                <div className="containerCarregarMais"
                                  onClick={() => {
                                    listaBaterias()
                                  }}>
                                  <p>Carregar mais baterias</p>
                                </div>
                              )
                            }
                          </div>
                          <div
                            className='chevron'
                            onClick={() => {
                              myRef.current.scrollTo(myRef.current.scrollLeft + 206.6, 0)
                            }}
                          >
                            <BsChevronRight />
                          </div>
                        </div>

                  }

                </LazyLoad>
              </div>
            </div>

            <div className='PainelVeiculos' style={{ display: focus === 'MANUAL' ? 'block' : "none" }}>
              <p onMouseDown={() => { setFocus(null) }} style={{ display: !focus ? 'none' : 'flex', alignItems: 'center' }} className='ContainerVoltar' ><BsChevronLeft /> Voltar</p>

              <div>
                <p className='span' >Digite o modelo do Veículo</p>
                <input
                  ref={inputRef}
                  defaultValue={textManual}
                  className='inputBusca'
                  placeholder={!textManual ? 'Ex: Palio 2017' : textManual}
                  onChange={debounce(1000, (text) => {
                    pesquisar(text.target.value.trim())
                  })} />
                <div >
                  {
                    veiculos.length > 0 ?
                      <>
                        <p className='InfoBateriaSelecionadaAH' style={{ padding: 10, fontStyle: 'italic', color: '#ccc' }}>Veículos Encontrados</p>
                        {
                          loading && (
                            <div className='ContainerNenhum'>
                              <p >Carregando...</p>
                            </div>
                          )
                        }
                        {
                          veiculos.map((el, index) => (
                            <ItemVeiculo
                              onClick={() => {
                                setSelecionado(el);
                              }}
                              key={index}
                              dados={el}
                            />
                          ))
                        }
                      </>
                      : loading && veiculos.length === 0 ?
                        <div className='ContainerNenhum'>
                          <p >Carregando...</p>
                        </div>
                        : veiculos.length === 0 && textManual !== '' && !loading ?
                          <div className='ContainerNenhum'>
                            <p >Nenhum veículo encontrado :(</p>
                          </div>
                          : null
                  }
                </div>
              </div>
            </div>

          </>


      }

    </div>
  );
}

export default App;
